import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const BuildingSpecialIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="71" viewBox="0 0 66 71" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#B48E57" transform="translate(-85 -142)">
          <g transform="translate(85 142)">
            <path d="M19.766 62.39H45.49V24.281H19.766V62.39zm7.942-40.975h9.84V10.187h-9.84v11.228zM1.433 65.256h62.39a1.435 1.435 0 100-2.867H48.356V22.85c0-.793-.641-1.434-1.433-1.434h-6.508V8.754c0-.792-.643-1.434-1.433-1.434h-4.921V1.434a1.434 1.434 0 00-2.868 0V7.32h-4.92c-.791 0-1.433.642-1.433 1.434v12.661h-6.51c-.79 0-1.433.641-1.433 1.434v39.54H1.433C.64 62.39 0 63.031 0 63.823c0 .791.64 1.433 1.433 1.433z"></path>
            <path d="M11.434 67.433a1.435 1.435 0 100 2.867h42.388a1.434 1.434 0 000-2.867H11.434"></path>
            <path d="M26.275 26.959c-.792 0-1.434.642-1.434 1.434v29.886a1.433 1.433 0 102.867 0V28.393c0-.792-.642-1.434-1.433-1.434"></path>
            <path d="M32.627 26.959c-.79 0-1.433.642-1.433 1.434v29.886a1.434 1.434 0 002.868 0V28.393c0-.792-.642-1.434-1.435-1.434"></path>
            <path d="M35.144 12.042h-5.03a1.433 1.433 0 100 2.867h5.03a1.433 1.433 0 000-2.867"></path>
            <path d="M35.144 16.572h-5.03a1.433 1.433 0 100 2.867h5.03a1.433 1.433 0 000-2.867"></path>
            <path d="M38.983 26.959c-.794 0-1.435.642-1.435 1.434v29.886a1.433 1.433 0 102.868 0V28.393c0-.792-.643-1.434-1.433-1.434"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BuildingSpecialIcon;
