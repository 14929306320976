import React, { forwardRef, Ref } from 'react';
import { buildClassName } from 'utils/build-class-name';
import Textarea from '@zoocasa/node-kit/components/controls/base/textarea';
import styles from './style.module.scss';

import type { TextareaProps } from '@zoocasa/node-kit/components/controls/base/textarea';
import type { FieldError, FieldErrors, Merge } from 'react-hook-form';

type Props = TextareaProps & {
  isHidden?: boolean;
  errors?: { message: string } | FieldError | Merge<FieldError, FieldErrors<any>>;
  testId?: string;
};

export default forwardRef(({ isHidden, errors, className, testId, ...textareaProps }: Props, ref?: Ref<HTMLTextAreaElement>) => {
  const componentClassName = buildClassName(styles.component, isHidden && styles.hidden);
  const textareaClassName = buildClassName(className, errors && styles.error);

  return (
    <div className={componentClassName}>
      <Textarea {...textareaProps} className={textareaClassName} ref={ref} data-testid={testId} />
      {errors && <span>{errors.message?.toString()}</span>}
    </div>
  );
});
