import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const CheckmarkCircleIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M24.515 11.606c.519.519.519 1.36 0 1.878l-8.91 8.91a1.328 1.328 0 01-1.878 0l-4.242-4.241a1.328 1.328 0 111.878-1.879l3.303 3.303 7.97-7.971a1.328 1.328 0 011.879 0zM34 17c0 9.397-7.605 17-17 17-9.397 0-17-7.605-17-17C0 7.603 7.605 0 17 0c9.397 0 17 7.605 17 17zm-2.656 0c0-7.929-6.416-14.344-14.344-14.344C9.071 2.656 2.656 9.072 2.656 17c0 7.929 6.416 14.344 14.344 14.344 7.929 0 14.344-6.416 14.344-14.344z"></path>
        </g>
      </g>
    </svg>
  );
};

export default CheckmarkCircleIcon;
