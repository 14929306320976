import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const CheckmarkIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="25" viewBox="0 0 31 25" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-856 -514)">
          <g transform="translate(69 370)">
            <g transform="translate(787 144)">
              <path d="M30.359.342a1.2 1.2 0 00-1.697.019L8.28 21.204 2.058 14.84a1.2 1.2 0 00-1.716 1.678l7.08 7.24a1.198 1.198 0 001.716 0l21.24-21.72a1.2 1.2 0 00-.019-1.697z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckmarkIcon;
