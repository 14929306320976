import { camelizeKeys, dasherizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import endpoint, { apiHeaders } from 'utils/endpoint';
import { HttpRequestMethodTypes } from 'types';
import { camelize } from '@zoocasa/node-kit/strings/camelize';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { FetchOptions } from 'types/fetchWithRetry';
import { ThemeName } from 'themes';

export type ListingConnRequest = {
  listingId: number;
  updatedOn: string;
};

type CreateConnectionRequestResponse = {
  data: Record<string, unknown>[];
  errors: {
    source?: {
      pointer?: string;
    };
    title?: string;
  }[];
};

export default class ConnectionRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  sourceComments: string;
  requestViewing: boolean | null;
  listingId: string;
  sellAddress: string;
  sellCity: string;
  sellProvince: string;
  sellPostalCode: string;
  sellBedrooms: string;
  sellBathrooms: string;
  sellHomeAge: string;
  sellLocationComments: string;
  buySell: 'buy' | 'sell' | 'both';
  leadSource: 
    'Property_Viewing_Request' |
    'Property_Info_Request' |
    'CMA' |
    'Connection_Request' |
    'Homepage' |
    'Our_Agents' |
    'Sign_Up';
  buyCity: string;
  buyProvince: string;
  theme: ThemeName;

  constructor(connectionRequest: Record<string, unknown>) {
    const camelizedListing = camelizeKeys(connectionRequest);
    const attributes = camelizedListing.attributes as Record<string, unknown>;
    const relationships = camelizedListing.relationships as Record<
      string,
      unknown
    >;
    const formattedListing = {
      ...attributes,
      ...relationships,
    };
    Object.assign(this, formattedListing);
  }
}

export async function getConnectionRequests() {
  let response: Response;
  try {
    response = await fetchWrapper('/services/api/v3/connection-requests');
  } catch (error) {
    // TypeError: Failed to fetch
    const wrappedError = new Error('There was an error querying connection requests', { cause: error as Error });
    console.error(wrappedError);
    return [];
  }
  const { payload } = await parseQueryResponse(response);
  if (Array.isArray(payload)) {
    return payload.map(i => camelizeKeys(i)) as ListingConnRequest[];
  }
  return [];
}

export async function createConnectionRequest(properties: Record<string, unknown>) {
  const response = await endpoint<CreateConnectionRequestResponse>('/services/api/v3/connection-requests', HttpRequestMethodTypes.POST, {
    data: {
      type: 'connection-requests',
      attributes: dasherizeKeys(properties),
    },
  });
  if ('errors' in response) {
    throw {
      errors: response.errors.map(({ source, title }) => {
        return {
          attribute: camelize((source?.pointer || '').replace('/data/attributes/', '')),
          message: title,
        };
      }),
    };
  }
  return response;
}

async function parseQueryResponse(response: Response) {
  if (response?.ok) {
    try {
      const payload: Record<string, unknown> | Record<string, unknown>[] = await response.json();
      return { payload, response, error: false };
    } catch (error) {
      if (error instanceof SyntaxError) {
        // Unexpected token < in JSON
        const wrappedError = new Error('Unexpected token in the response', { cause: error as Error });
        console.error(wrappedError);
      } else {
        const wrappedError = new Error('There was an error parsing the response', { cause: error as Error });
        console.error(wrappedError);
      }
      return { payload: null, response, error };
    }
  }
  return { payload: null, response, error: true };
}

async function fetchWrapper(url: string, options: FetchOptions = { headers: apiHeaders() }) {
  return await fetchWithRetry(url, options);
}
