import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const HouseSpecialIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="66" viewBox="0 0 74 66" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#B48E57" transform="translate(-248 -145)">
          <g transform="translate(248 145)">
            <path d="M45.643 45.9h1.414c.848 0 1.543-.694 1.543-1.541v-1.416c0-.848-.695-1.543-1.543-1.543h-1.414c-.848 0-1.543.695-1.543 1.543v1.416c0 .847.695 1.541 1.543 1.541"></path>
            <path d="M51.944 51.3h1.413c.848 0 1.543-.695 1.543-1.542v-1.416c0-.847-.695-1.542-1.543-1.542h-1.413a1.55 1.55 0 00-1.544 1.542v1.416a1.55 1.55 0 001.544 1.542"></path>
            <path d="M51.944 45.9h1.413c.848 0 1.543-.694 1.543-1.541v-1.416c0-.848-.695-1.543-1.543-1.543h-1.413a1.55 1.55 0 00-1.544 1.543v1.416c0 .847.696 1.541 1.544 1.541"></path>
            <path d="M45.643 51.3h1.414c.848 0 1.543-.695 1.543-1.542v-1.416c0-.847-.695-1.542-1.543-1.542h-1.414c-.848 0-1.543.695-1.543 1.542v1.416c0 .847.695 1.542 1.543 1.542"></path>
            <path d="M5.614 31.204L36.9 4.684l31.286 26.52H5.614zm54.281 31.48H32.813V49.266c0-3.407-2.766-6.179-6.167-6.18-3.4.001-6.164 2.773-6.164 6.18v13.416h-6.576V34.221h45.99v28.462zm-30.093 0h-6.31V49.266a3.163 3.163 0 013.154-3.163c1.74 0 3.156 1.42 3.156 3.163v13.416zm-14.3-59.667h6.578v10.28l-6.578 5.574V3.017zM73.266 31.56L37.872 1.56a1.501 1.501 0 00-1.944 0L25.09 10.745V1.508C25.09.676 24.415 0 23.585 0h-9.588c-.83 0-1.504.676-1.504 1.508v19.914L.533 31.561a1.51 1.51 0 00.972 2.66h9.39v29.97c0 .834.675 1.509 1.504 1.509h49c.832 0 1.505-.675 1.505-1.508V34.22h9.392a1.51 1.51 0 00.97-2.66z"></path>
            <path d="M18.9 27.45c0 .745.506 1.35 1.133 1.35h34.634c.626 0 1.133-.605 1.133-1.35 0-.746-.507-1.35-1.133-1.35H20.033c-.627 0-1.133.604-1.133 1.35"></path>
            <path d="M28.8 20.25c0 .745.497 1.35 1.11 1.35h13.079c.612 0 1.111-.605 1.111-1.35 0-.747-.499-1.35-1.111-1.35H29.91c-.613 0-1.11.603-1.11 1.35"></path>
            <path d="M24.3 23.85c0 .746.514 1.35 1.147 1.35h23.804c.634 0 1.149-.604 1.149-1.35 0-.746-.515-1.35-1.149-1.35H25.447c-.633 0-1.147.604-1.147 1.35"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HouseSpecialIcon;
