import React from 'react';
import { trackEvent } from 'utils/google-tag-manager';
import PhoneIcon from 'components/icon/phone-icon';
import styles from '../style.module.scss';

export default function PhoneCTA() {
  return (
    <div className={styles['phone-number-container']}>
      <a href="tel:1-844-683-4663" className={styles['phone-number']} onClick={() => trackEvent('click_call')}>
        <PhoneIcon /> Call us 1-844-683-4663
      </a>
    </div>
  );
}
