import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const EyeOpenIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#191B1E" fillRule="nonzero">
          <path d="M24.451 7.159C22.015 2.862 17.464 0 12.288 0 7.114 0 2.561 2.86.125 7.158a.96.96 0 000 .947c2.436 4.297 6.987 7.159 12.163 7.159 5.174 0 9.727-2.86 12.163-7.158a.96.96 0 000-.947zm-12.163 6.185c-4.275 0-8.05-2.267-10.21-5.712C4.238 4.188 8.01 1.92 12.288 1.92c4.275 0 8.05 2.267 10.21 5.712-2.16 3.444-5.933 5.712-10.21 5.712z"></path>
          <path d="M12.288 4.272a3.364 3.364 0 00-3.36 3.36 3.364 3.364 0 003.36 3.36 3.364 3.364 0 003.36-3.36 3.364 3.364 0 00-3.36-3.36zm0 4.8c-.794 0-1.44-.646-1.44-1.44 0-.794.646-1.44 1.44-1.44.794 0 1.44.646 1.44 1.44 0 .794-.646 1.44-1.44 1.44z"></path>
        </g>
      </g>
    </svg>
  );
};

export default EyeOpenIcon;
