import React from 'react';
import BuildingSpecialIcon from 'components/icon/building-special-icon';
import SchoolSpecialIcon from 'components/icon/school-special-icon';
import HouseSpecialIcon from 'components/icon/house-special-icon';
import styles from './style.module.scss';

export default function ConnectionRequestIcons() {
  return (
    <div className={styles.component}>
      <BuildingSpecialIcon />
      <SchoolSpecialIcon />
      <HouseSpecialIcon />
    </div>
  );
}
