import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const SchoolSpecialIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="61" viewBox="0 0 62 61" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#B48E57" transform="translate(-169 -149)">
          <g transform="translate(169 149)">
            <path d="M2.927 12.218L5.95 2.49h49.2l3.021 9.728H2.927zM54.43 57.99h-18.8V47.026c0-2.811-2.278-5.1-5.082-5.1-2.803 0-5.081 2.289-5.081 5.1v10.965h-18.8V14.707H54.43V57.99zm-21.28 0h-5.203V47.026c0-1.44 1.167-2.61 2.6-2.61a2.608 2.608 0 012.603 2.61v10.965zM57.246.874A1.243 1.243 0 0056.063 0H5.038c-.543 0-1.024.356-1.185.874L.057 13.09a1.24 1.24 0 001.182 1.616h2.948v44.528c0 .687.554 1.245 1.24 1.245h50.245c.685 0 1.241-.558 1.241-1.245V14.707h2.945a1.241 1.241 0 001.252-1.245c0-.19-.042-.372-.119-.533L57.246.874z"></path>
            <path d="M12.618 23.426h3.114v-3.383h-3.114v3.383zM11.35 25.83h5.652c.7 0 1.269-.539 1.269-1.203v-5.786c0-.662-.568-1.201-1.27-1.201h-5.65c-.702 0-1.27.539-1.27 1.201v5.786c0 .664.568 1.203 1.27 1.203z"></path>
            <path d="M23.33 23.426h3.11v-3.383h-3.11v3.383zm-1.271 2.404h5.652c.701 0 1.269-.539 1.269-1.203v-5.786c0-.662-.568-1.201-1.269-1.201h-5.652c-.701 0-1.269.539-1.269 1.201v5.786c0 .664.568 1.203 1.269 1.203z"></path>
            <path d="M34.038 23.426h3.114v-3.383h-3.114v3.383zM32.77 25.83h5.652c.701 0 1.269-.539 1.269-1.203v-5.786c0-.662-.568-1.201-1.269-1.201h-5.652c-.701 0-1.269.539-1.269 1.201v5.786c0 .664.568 1.203 1.269 1.203z"></path>
            <path d="M45.378 23.426h3.114v-3.383h-3.114v3.383zM44.11 25.83h5.652c.701 0 1.269-.539 1.269-1.203v-5.786c0-.662-.568-1.201-1.269-1.201h-5.652c-.7 0-1.269.539-1.269 1.201v5.786c0 .664.57 1.203 1.269 1.203z"></path>
            <path d="M12.618 36.657h3.114v-3.383h-3.114v3.383zM11.35 39.06h5.652c.7 0 1.269-.538 1.269-1.201V32.07c0-.662-.568-1.201-1.27-1.201h-5.65c-.702 0-1.27.539-1.27 1.201v5.788c0 .663.568 1.201 1.27 1.201z"></path>
            <path d="M23.33 36.657h3.11v-3.383h-3.11v3.383zm-1.271 2.403h5.652c.701 0 1.269-.538 1.269-1.201V32.07c0-.662-.568-1.201-1.269-1.201h-5.652c-.701 0-1.269.539-1.269 1.201v5.788c0 .663.568 1.201 1.269 1.201z"></path>
            <path d="M34.038 36.657h3.114v-3.383h-3.114v3.383zM32.77 39.06h5.652c.701 0 1.269-.538 1.269-1.201V32.07c0-.662-.568-1.201-1.269-1.201h-5.652c-.701 0-1.269.539-1.269 1.201v5.788c0 .663.568 1.201 1.269 1.201z"></path>
            <path d="M45.378 36.657h3.114v-3.383h-3.114v3.383zM44.11 39.06h5.652c.701 0 1.269-.538 1.269-1.201V32.07c0-.662-.568-1.201-1.269-1.201h-5.652c-.7 0-1.269.539-1.269 1.201v5.788c0 .663.57 1.201 1.269 1.201z"></path>
            <path d="M12.618 49.256h3.114v-3.383h-3.114v3.383zM11.35 51.66h5.652c.7 0 1.269-.539 1.269-1.201V44.67c0-.663-.568-1.201-1.27-1.201h-5.65c-.702 0-1.27.538-1.27 1.201v5.788c0 .662.568 1.201 1.27 1.201z"></path>
            <path d="M45.378 49.256h3.114v-3.383h-3.114v3.383zM44.11 51.66h5.652c.701 0 1.269-.539 1.269-1.201V44.67c0-.663-.568-1.201-1.269-1.201h-5.652c-.7 0-1.269.538-1.269 1.201v5.788c0 .662.57 1.201 1.269 1.201z"></path>
            <path d="M51.048 8.19H10.062c-.686 0-1.242.565-1.242 1.26 0 .696.556 1.26 1.242 1.26h40.986a1.25 1.25 0 001.242-1.26c0-.695-.556-1.26-1.242-1.26"></path>
            <path d="M15.716 6.93h28.418c.678 0 1.226-.564 1.226-1.26 0-.696-.548-1.26-1.226-1.26H15.716c-.676 0-1.226.564-1.226 1.26 0 .696.55 1.26 1.226 1.26"></path>
            <path d="M30.24 49.77a1.26 1.26 0 100 2.52 1.26 1.26 0 000-2.52"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SchoolSpecialIcon;
