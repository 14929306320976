import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const EyeClosedIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 25 22" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#191B1E" fillRule="nonzero">
          <path d="M24.207 10.366c-2.413-4.255-6.92-7.087-12.042-7.087-1.04 0-2.071.119-3.076.35L7.261.474a.95.95 0 00-1.644.953l1.607 2.775c-3.002 1.162-5.51 3.358-7.1 6.164a.95.95 0 000 .937c2.412 4.255 6.919 7.087 12.041 7.087 1.04 0 2.072-.119 3.076-.35l1.828 3.155a.95.95 0 001.645-.953l-1.608-2.775c3.002-1.162 5.51-3.358 7.1-6.164a.95.95 0 000-.937zm-12.042 6.123c-4.234 0-7.97-2.245-10.108-5.654 1.41-2.25 3.547-4.038 6.134-4.965l1.555 2.685a3.314 3.314 0 00-.907 2.28 3.33 3.33 0 004.101 3.234l1.296 2.237a11.79 11.79 0 01-2.07.183zm-1.425-5.654c0-.155.025-.304.07-.443l1.065 1.838a1.428 1.428 0 01-1.135-1.395zm1.715-1.396a1.428 1.428 0 011.065 1.838L12.455 9.44zm3.684 6.36l-1.555-2.685a3.313 3.313 0 00.908-2.28A3.33 3.33 0 0011.39 7.6l-1.296-2.237a11.785 11.785 0 012.071-.183c4.234 0 7.97 2.245 10.108 5.655-1.41 2.248-3.547 4.038-6.134 4.964z"></path>
        </g>
      </g>
    </svg>
  );
};

export default EyeClosedIcon;
